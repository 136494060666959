<template>
    <div>
        <div style="margin-top: 10px;margin-bottom: 0;" class="divider">
            <span style="color:#666;padding-left: 5px"><v-icon style="color:#666!important">list_alt</v-icon> Today's Routine <small>({{nd.format('MMM D, DDD ')}})</small></span>
        </div>


        <div class="periods-list" style="padding-left: 0;">
            <ul>
                <li v-for="(period,key) in periods" :key="key"><a href=""
                                                                  :class="period.id===selectedPeriod.id?'active':''"
                                                                  @click.prevent="selectPeriod(period)">{{period.period}}<sup>{{getThForm(period.period)}}</sup></a>
                </li>
            </ul>

            <div v-if="Object.keys(currentPeriod).length>0 && Object.keys(selectedPeriod).length===0"
                 class="period-data">

                <div style="margin-top: 0;margin-bottom: 0;" class="divider">
                    <span style="color:#ccc;padding-left: 5px;"><small>NOW</small></span>
                </div>
                <div class="card-period">
                    <div>
                        <h3>{{currentPeriod.subject?currentPeriod.subject.name:''}}</h3>
                        <p>{{currentPeriod.routine_type?currentPeriod.routine_type.toUpperCase():''}}</p>

                        <h4>{{currentPeriod.grade?currentPeriod.grade.name:''}} /
                            {{currentPeriod.section?currentPeriod.section.name:''}}</h4>
                    </div>
                    <div style="margin-top: 20px;">
                        <p style="margin-bottom: 5px;cursor: pointer"><strong class="starts_at">
                            <v-icon>watch_later</v-icon>
                            {{currentPeriod.start_time}}</strong></p>
                        <p style="cursor: pointer"><strong class="ends_at">
                            <v-icon>watch_later</v-icon>
                            {{currentPeriod.end_time}}</strong></p>
                    </div>
                </div>


                <div style="margin-top: 0;margin-bottom: 0;" class="divider">
                    <span style="color:#ccc;padding-left: 5px;"><small>NEXT</small></span>
                </div>

                <div v-if="Object.keys(fetchNextPeriod()).length>0" class="card-period">
                    <div>
                        <h3>Mathematics</h3>
                        <p>Theory</p>

                        <h4>12 / Section A</h4>
                    </div>
                    <div style="margin-top: 20px;">
                        <p style="margin-bottom: 5px;cursor: pointer"><strong class="starts_at">
                            <v-icon>watch_later</v-icon>
                            10:00</strong></p>
                        <p style="cursor: pointer"><strong class="ends_at">
                            <v-icon>watch_later</v-icon>
                            10:40</strong></p>
                    </div>
                </div>
                <div v-else>
                    <div>
                        <h3 style="color:#f5cdcd;">No More Periods for today.</h3>
                    </div>
                </div>

            </div>
            <div class="card-period" v-else-if="Object.keys(selectedPeriod).length>0">
                <div>
                    <h3>{{selectedPeriod.subject?selectedPeriod.subject.name:''}}</h3>
                    <p>{{selectedPeriod.routine_type?selectedPeriod.routine_type.toUpperCase():''}}</p>

                    <h4>{{selectedPeriod.grade?selectedPeriod.grade.name:''}} /
                        {{selectedPeriod.section?selectedPeriod.section.name:''}}</h4>
                </div>
                <div style="margin-top: 20px;">
                    <p style="margin-bottom: 5px;cursor: pointer"><strong class="starts_at">
                        <v-icon>watch_later</v-icon>
                        {{selectedPeriod.start_time}}</strong></p>
                    <p style="cursor: pointer"><strong class="ends_at">
                        <v-icon>watch_later</v-icon>
                        {{selectedPeriod.end_time}}</strong></p>
                </div>
            </div>
            <div v-else>
                <h3 style="color:#f5cdcd">You have no period left today.</h3>
            </div>
        </div>
    </div>
</template>

<script>
    import {getThForm} from "../../../../library/helpers";

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    export default {
        data() {
            return {
                periods: [],
                nd,
                selectedPeriod: {}
            }
        },
        computed: {
            currentPeriod() {
                let currentPeriod = {};
                let today = new Date();
                let hours = today.getHours();
                let minutes = today.getMinutes();
                let seconds = today.getSeconds();
                // hours = hours % 12;
                // hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0' + minutes : minutes;
                seconds = seconds < 10 ? '0' + seconds : seconds;
                // hours = hours < 10 ? '0' + hours : hours;

                const nowTime = hours + ":" + minutes + ":" + seconds;

                var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;

                this.periods.map(function (item) {
                    let endTime = item.end_time + ':00';
                    let startTime = item.start_time + ':00';

                    if (parseInt(startTime.replace(regExp, "$1$2$3")) < parseInt(nowTime.replace(regExp, "$1$2$3")) && parseInt(endTime.replace(regExp, "$1$2$3")) > parseInt(nowTime.replace(regExp, "$1$2$3"))) {
                        currentPeriod = item;
                    }
                });
                return currentPeriod;
            }
        },
        mounted() {
            this.fetchMyRoutine();
        },
        methods: {
            fetchMyRoutine(params) {
                this.$rest.get('/api/teacher-routine?sortBy=period&day=today').then(({data}) => {
                    this.periods = data.data;
                })
            },
            getThForm,
            selectPeriod(period) {
                let $this = this;
                this.periods.map(function (item) {
                    if (period.id === item.id) {
                        $this.selectedPeriod = item;
                    }
                });
            },
            fetchNextPeriod() {
                if (Object.keys(this.currentPeriod).length < 1) return {};

                let output = {};
                let $this = this;
                try {

                    this.periods.map(function (item, key) {
                        if (item.id === this.currentPeriod.id) {
                            output = $this.periods[key + 1];
                            throw Error('check');
                        }
                    });
                } catch (e) {

                }
                return output;
            }
        }
    }
</script>
<style lang="scss">
    .periods-list {
        display: flex;
        margin-top: 15px;
        padding: 10px;
        float: left;
        ul {
            padding: 0;
            margin-right: 15px;
            li {

                a {
                    transition: 0.3s;
                    background: #ecf3fb;
                    padding: 7px 10px;
                    text-align: center;
                    display: inline-block;
                    width: 50px;
                    font-size: 13px;
                    &.active {
                        width: 55px;
                        background: #d0e5fb;
                    }
                    /*width: 30px;*/
                    color: #1565c0 !important;
                    text-decoration: none;
                    font-weight: bold;
                    border-bottom: 1px solid #cbd6e0;

                }
                &:last-child {
                    a {
                        border-bottom: none;
                    }
                }
                list-style: none;
            }
        }

        .period-data {
            /*margin-top: 20px;*/
            width: 100%;
            padding: 0 15px 0 0;
        }
        .card-period {
            padding: 0 0 15px 0;
            display: flex;
            justify-content: space-between;
            h3 {
                color: #444;
                line-height: 1;
                + p {
                    color: #c0c0c0;
                    margin-bottom: 10px;
                }
            }
            h4 {
                color: #777;
            }
        }
        .ends_at {
            color: #db4949;
            padding: 2px 5px;
            background: #ffefef;
            font-size: 12px;
            border-radius: 4px;
            i {
                color: #db4949;
                font-size: 18px;
            }
        }
        .starts_at {
            border-radius: 4px;
            padding: 2px 5px;
            font-size: 12px;
            color: #2ece5a;
            i {
                color: #2ece5a;
                font-size: 18px;
            }
            background: #e0f9e8;
        }
    }
</style>